import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

export const AppContext = React.createContext({
  location: {},
  setLocation: () => {},
  cookies: {},
  setCookie: () => {},
  removeCookie: () => {},
  roomId: null,
  setRoomId: () => {},
  roomRef: {},
  setRoomRef: () => {},
  users: [],
  setUsers: () => {},
  cardChoice: null,
  setCardChoice: () => {},
  allCardsShown: false,
  setAllCardsShown: () => {},
  numbers: [],
  setNumbers: () => {}
});

export const AppContextProvider = ({ children }) => {
  const context = React.useContext(AppContext);
  const [cookies, setCookie, removeCookie] = useCookies(['user']);

  const [location, setLocation] = useState(context.location);
  const [roomId, setRoomId] = useState(context.roomId);
  const [users, setUsers] = useState(context.users);
  const [cardChoice, setCardChoice] = useState(context.cardChoice);
  const [allCardsShown, setAllCardsShown] = useState(context.allCardsShown);
  const [numbers, setNumbers] = useState(context.numbers);

  return (
    <AppContext.Provider
      value={{
        location,
        setLocation,
        cookies,
        setCookie,
        removeCookie,
        roomId,
        setRoomId,
        users,
        setUsers,
        cardChoice,
        setCardChoice,
        allCardsShown,
        setAllCardsShown,
        numbers,
        setNumbers
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

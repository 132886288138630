import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50vh',
    height: '50vh',
    borderRadius: '1000px',
    backgroundColor: 'lightgray'
  }
}));

const Table = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.circle}>
      {children}
    </Box>
  );
};

export default Table;
import React, { useState, useEffect, useContext } from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Table from './Table';
import Card from './Card';
import { AppContext } from '../../context/AppContextProvider';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative'
  },
  button: {
    width: '200px',
    height: '50px',
    color: 'white',
    fontFamily: 'Poppins'
  }
}));

const RoundTable = ({ showAllCards }) => {
  const { users, allCardsShown, roomId } = useContext(AppContext);
  const classes = useStyles();

  const [cards, setCards] = useState([]);

  const buildCircle = (users) => {
    let radius = 'calc(100vh / 5)';
    let start = 90;
    let slice = 360 / users.length;
  
    let newCards = [];
    for (let i = 0; i < users.length; i++) {
      let rotate = slice * i + start;
      let rotateReverse = rotate * -1;
  
      newCards.push({
        user: {
          userId: users[i].userId,
          name: users[i].name
        },
        number: users[i].cardChoice,
        css: {
          radius: radius,
          rotate: rotate,
          rotateReverse: rotateReverse
        }
      });
    }
    setCards(newCards);
  };

  useEffect(() => {
    buildCircle(users);
  }, [users]);

  return (
    <Box className={classes.container}>
      <Table>
        <Button
          className={classes.button}
          style={{ backgroundColor: allCardsShown ? 'gray' : '#d1750d' }}
          disabled={allCardsShown}
          onClick={() => showAllCards(roomId)}
        >
          Show Cards
        </Button>
        {cards.map((card, i) => {
          return <Card key={i} card={card} idx={i} />;
        })}
      </Table>
    </Box>
  );
};

export default RoundTable;
import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AppContext } from '../../context/AppContextProvider';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '150px',
    height: '260px',
    borderRadius: '8px',
    backgroundColor: 'lightgray'
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '20px'
  },
  numberContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
    width: '100%',
    borderRadius: '8px'
  },
  resultNumber: {
    fontSize: '24px',
    fontFamily: 'Poppins'
  }
}));

const Stats = () => {
  const classes = useStyles();
  const { numbers } = useContext(AppContext);

  const [mostCommon, setMostCommon] = useState(null);

  const getMostCommonNumbers = (nums) => {
    const numberCounts = {};

    nums.forEach(num => {
      if (!numberCounts[num]) {
        numberCounts[num] = 1;
      } else {
        numberCounts[num] += 1;
      }
    });

    const getMax = numberCounts => {
      return Object.keys(numberCounts).filter(card => {
        return numberCounts[card] == Math.max.apply(null, 
          Object.values(numberCounts));
      });
    };

    return getMax(numberCounts);
  };

  const getAverage = (nums) => {
    if (nums) {
      let sum = 0;
      nums.forEach(num => {
        if (!isNaN(num)) {
          sum += Number(num);
        }
      });
      const result = Math.round(sum / nums.filter(n => !isNaN(n)).length * 2) / 2;
      if (isNaN(result)) {
        return '';
      } else {
        return result;
      }
    }
    return null;
  };

  useEffect(() => {
    if (numbers && numbers.length) {
      setMostCommon(getMostCommonNumbers(numbers));
    } else {
      setMostCommon(null);
    }
  }, [numbers]);

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>Most Chosen</Typography>
      <Box className={classes.numberContainer}>
        <Typography className={classes.resultNumber}>
          {mostCommon && mostCommon.join(' / ')}
        </Typography>
      </Box>
      <Typography className={classes.title}>Average</Typography>
      <Box className={classes.numberContainer}>
        <Typography className={classes.resultNumber}>
          {numbers && getAverage(numbers)}
        </Typography>
      </Box>
    </Box>
  );
};

export default Stats;

import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useForm, Controller } from 'react-hook-form';
import {
  validate as uuidValidate,
  version as uuidVersion
} from 'uuid';
import { AppContext } from '../../context/AppContextProvider';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#21262b'
  },
  wrapper: {
    width: '650px',
    height: '350px',
    backgroundColor: 'lightgray',
    borderRadius: '8px'
  },
  form: {
    display: 'flex',
    gap: '8px'
  },
  roomIdField: {
    width: '400px'
  },
  roomIdDesc: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 500,
    color: 'gray'
  },
  roomIdError: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 300,
    color: 'red'
  },
  joinButton: {
    backgroundColor: '#d1750d',
    color: 'white',
    height: '40px',
    width: '80px',
    fontFamily: 'Poppins'
  },
  createNewGameButton: {
    backgroundColor: '#d1750d',
    color: 'white',
    height: '50px',
    width: '400px',
    fontFamily: 'Poppins',
    fontSize: '20px'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '12px',
  },
  link: {
    color: 'white'
  },
  title: {
    fontSize: '48px',
    fontFamily: 'Poppins',
    color: 'white',
    marginBottom: '40px'
  }
}));

const Welcome = ({ createNewRoomOrAddUser }) => {
  const { cookies } = useContext(AppContext);
  const { handleSubmit, watch, formState: { errors }, control, getValues } = useForm();

  const [showNameError, setShowNameError] = useState(false);

  const uuidValidateV4 = (uuid) => {
    return uuidValidate(uuid) && uuidVersion(uuid) === 4;
  };

  const onSubmit = (data) => {
    console.log(data);
    createNewRoomOrAddUser(data.name, data.roomUuid);
  };

  useEffect(() => {
    if (watch('name') && showNameError) {
      setShowNameError(false);
    }
  }, [watch('name')]);

  const classes = useStyles();
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.container}>
          <Typography className={classes.title}>Planning Poker</Typography>
          <Box className={classes.wrapper}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
              alignItems="center"
              gridGap="42px"
            >
              <Box width="min-content">
                <Typography className={classes.roomIdDesc}>Enter your name:</Typography>
                <Box className={classes.form}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={cookies.name || ''}
                    render={({ field }) => (
                      <TextField
                        { ...field }
                        className={classes.roomIdField}
                        variant="outlined"
                        margin="none"
                        hiddenLabel={true}
                        size="small"
                        inputProps={{ style: { fontFamily: 'Poppins' } }}
                      />
                    )}>
                  </Controller>
                </Box>
                {((errors && errors.name) || showNameError) && (
                  <Typography className={classes.roomIdError}>
                      Please enter your name.
                  </Typography>
                )}
              </Box>
              <Button
                className={classes.createNewGameButton}
                onClick={() => {
                  if (!getValues('name')) {
                    setShowNameError(true);
                    return;
                  }
                  createNewRoomOrAddUser(getValues('name'));
                }}
              >
                Create new game room
              </Button>
              <Box width="min-content">
                <Typography className={classes.roomIdDesc}>Or enter room ID:</Typography>
                <Box className={classes.form}>
                  <Controller
                    name="roomUuid"
                    control={control}
                    rules={{ required: true, validate: value => uuidValidateV4(value) }}
                    defaultValue={''}
                    render={({ field }) => (
                      <TextField
                        { ...field }
                        className={classes.roomIdField}
                        variant="outlined"
                        margin="none"
                        hiddenLabel={true}
                        size="small"
                        inputProps={{ style: { fontFamily: 'Poppins' } }}
                      />
                    )}>
                  </Controller>
                  <Button className={classes.joinButton} variant="filled" type="submit">Join</Button>
                </Box>
                {errors && errors.roomUuid && (
                  <Typography className={classes.roomIdError}>
                      Please enter a valid room ID or create a new room.
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
      <Typography className={classes.footer}>
        <a
          className={classes.link}
          href="https://github.com/dontpressthat"
          target="_blank"
          rel="noreferrer"
        >
              GitHub
        </a>
      </Typography>
    </>
  );
};

export default Welcome;
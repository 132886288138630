import React, { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AppContext } from '../../context/AppContextProvider';

const useStyles = makeStyles(() => ({
  cardContainer: {
    position: 'absolute',
    left: 'calc(100% / 2.15)',
    top: 'calc(100% / 2.2)',
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    width: 'calc(100vh / 30)',
    minWidth: '25px',
    minHeight: '35px',
    height: 'calc(100vh / 20)',
    color: 'white',
    boxShadow: '0 0 5px gray'
  },
  numberContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  number: {
    fontFamily: 'Poppins',
    fontSize: '20px'
  },
  name: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    position: 'absolute',
    top: '105%',
    whiteSpace: 'nowrap'
  }
}));

const Card = ({ card }) => {
  const classes = useStyles();
  const {
    cookies,
    allCardsShown
  } = useContext(AppContext);

  const isMyCard = (card) => {
    return card.user.userId === cookies.userId;
  };

  return (
    <Box
      className={classes.cardContainer}
      style={{
        transform:
          'rotate(' +
          card.css.rotate +
          'deg) translate(' +
          card.css.radius +
          ') rotate(' +
          card.css.rotateReverse +
          'deg)'
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          className={classes.card}
          style={{ backgroundColor: card.number ? '#d1750d' : 'white' }}
        >
          {isMyCard(card) || allCardsShown ? (
            <Typography className={classes.number}>
              {card.number}
            </Typography>
          ) : null}
        </Box>
        <Typography className={classes.name}>
          {isMyCard(card) ? `Me (${card.user.name})` : card.user.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default Card;

import React, { useEffect, useState, useContext } from 'react';
import { Box, Dialog, Typography, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import { useForm, Controller } from 'react-hook-form';
import { useBeforeunload } from 'react-beforeunload';
import './App.css';
import RoundTable from './components/RoundTable';
import Welcome from './components/Welcome';
import { AppContext } from './context/AppContextProvider';
import {
  db,
  analytics,
  deleteUserFromGameRoom,
  createNewGameRoomOrAddUser,
  saveCardChoiceNum,
  showAllCardsOnTable,
  resetCardsOnTable,
  getAllNumbersOnTable,
  resetNumbersOnTable
} from './firebase/server';
import CardChoices from './components/CardChoices';
import Stats from './components/Stats';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    backgroundColor: '#21262b'
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '400px',
    height: '200px',
    borderRadius: '4px',
    backgroundColor: 'lightgray'
  },
  nameField: {
    width: '300px'
  },
  nameDesc: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 500,
    color: 'gray'
  },
  nameError: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 300,
    color: 'red'
  },
  joinButton: {
    backgroundColor: '#d1750d',
    color: 'white',
    height: '40px',
    width: '300px',
    fontFamily: 'Poppins'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    transform: 'translate(0, -50%)',
    fontSize: '12px',
  },
  link: {
    color: 'white'
  },
  tableContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px'
  },
  resetButton: {
    color: 'white',
    fontFamily: 'Poppins'
  },
  chooseYourCard: {
    color: 'white',
    fontFamily: 'Poppins'
  }
}));

const App = () => {
  const {
    cookies,
    setCookie,
    setRoomId,
    roomId,
    users,
    setUsers,
    removeCookie,
    setCardChoice,
    setAllCardsShown,
    allCardsShown,
    setNumbers
  } = useContext(AppContext);
  const classes = useStyles();

  const { handleSubmit, formState: { errors }, control } = useForm();

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (window.location && window.location.search && window.location.search) {
      const qs = queryString.parse(window.location.search);
      if (qs.room) setRoomId(qs.room);
    }
  }, []);

  useEffect(() => {
    const roomRef = db.ref(`rooms/${roomId}`);
    const usersRef = roomRef.child('users');
    usersRef.on('value', (snapshot) => {
      const data = snapshot.val();
      const roomUsers = (Object.values(data || {})
        .sort((x,y) => {
          return x.userId === cookies.userId ? -1 : y.userId === cookies.userId ? 1 : 0;
        }));
      setUsers(roomUsers);
    });
  }, [roomId]);

  useEffect(() => {
    if (roomId) {
      const roomRef = db.ref(`rooms/${roomId}`);
      roomRef.on('value', (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setAllCardsShown(data.showAllCards);
        }
      });
    }
  }, [roomId]);

  useEffect(() => {
    if (roomId) {
      const roomRef = db.ref(`rooms/${roomId}`);
      roomRef.child('showAllCards').on('value', (snapshot) => {
        const showAllCards = snapshot.val();
        if (!showAllCards) setCardChoice(null);
      });
    }
  }, [roomId]);

  useEffect(() => {
    if (roomId) {
      const roomRef = db.ref(`rooms/${roomId}`);
      roomRef.child('numbers').on('value', (snapshot) => {
        const numbers = snapshot.val();
        setNumbers(numbers);
      });
    }
  }, [roomId]);

  const createNewRoomOrAddUser = async (name, roomId, userId) => {
    const userUuid = userId || uuidv4();
    let isNewRoom = false;
    const createNewRoom = async () => {
      const newRoomId = uuidv4();
      analytics.logEvent('new_game_created', {
        msg: `New room created by ${name || '[No name]'}: ${newRoomId}`
      });
      isNewRoom = true;
      console.log(isNewRoom);
      return newRoomId;
    };
    const roomUuid = roomId || await createNewRoom();
    setCookie('name', name, { path: '/' });
    setCookie('userId', userUuid, { path: '/' });
    createNewGameRoomOrAddUser(roomUuid, userUuid, name, isNewRoom).then(() => {
      window.location.href = `/?room=${roomUuid}`;
    });
  };

  useEffect(() => {
    if (roomId && (!cookies.name || !cookies.userId)) {
      setDialogOpen(true);
    }
  }, [roomId]);

  useEffect(() => {
    if (roomId && users && cookies.userId) {
      const addUserWithExistingId = async () => {
        const roomRef = db.ref(`rooms/${roomId}`);
        const usersRef = roomRef.child('users');
        const userRecords = await usersRef.get().then(snapshot => snapshot.val());

        const existingUser = Object.values(userRecords).find(el => el.userId === cookies.userId);

        if (!existingUser) {
          createNewRoomOrAddUser(cookies.name, roomId, cookies.userId);
        }
      };
      addUserWithExistingId();
    }
  }, [roomId]);

  const onSubmit = (data) => {
    createNewRoomOrAddUser(data.name, roomId);
    setDialogOpen(false);
  };

  useBeforeunload(() => {
    if (roomId && (cookies.name || cookies.userId)) {
      deleteUserFromGameRoom(roomId, cookies.userId);
      removeCookie('name');
      removeCookie('userId');
    }
  });

  const saveCardChoice = (number) => {
    saveCardChoiceNum(roomId, cookies.userId, number)
      .then((num) => {
        setCardChoice(num);
      });
  };

  const getNumbers = async () => {
    await getAllNumbersOnTable(roomId);
  };

  const showAllCards = () => {
    getNumbers();
    showAllCardsOnTable(roomId)
      .then(() => {
        setAllCardsShown(true);
      });
  };

  const resetCards = async () => {
    resetCardsOnTable(roomId)
      .then(() => {
        setAllCardsShown(false);
      });
    await resetNumbersOnTable(roomId);
  };

  return (
    <>
      <Dialog disableBackdropClick disableEscapeKeyDown open={dialogOpen}>
        <Box className={classes.dialog}>
          <form id="name" onSubmit={handleSubmit(onSubmit)}>
            <Box width="min-content">
              <Typography className={classes.nameDesc}>Enter your name:</Typography>
              <Box className={classes.form}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={''}
                  render={({ field }) => (
                    <TextField
                      { ...field }
                      className={classes.nameField}
                      variant="outlined"
                      margin="none"
                      hiddenLabel={true}
                      size="small"
                      inputProps={{ style: { fontFamily: 'Poppins' } }}
                    />
                  )}>
                </Controller>
              </Box>
              {errors && errors.name && (
                <Typography className={classes.nameError}>
                    Please enter your name.
                </Typography>
              )}
            </Box>
          </form>
          <Button
            form="name"
            className={classes.joinButton}
            variant="filled"
            type="submit"
          >
            Join
          </Button>
        </Box>
      </Dialog>
      {roomId ? (
        <Box className={classes.container}>
          <Box className={classes.tableContainer}>
            <Box width="150px" height="250px" />
            <RoundTable
              users={users}
              showAllCards={showAllCards}
              resetCards={resetCards}
            />
            <Box
              display="flex"
              flexDirection="column"
              gridGap="10px"
              marginTop="70px"
            >
              <Stats />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Button
                  className={classes.resetButton}
                  onClick={() => resetCards(roomId)}
                  disabled={!allCardsShown}
                  variant="filled"
                  style={{ backgroundColor: !allCardsShown ? 'gray' : '#d1750d' }}
                >
                  Reset Cards
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            {!allCardsShown ? (
              <Box height="30px">
                <Typography className={classes.chooseYourCard}>Choose your card!</Typography>
              </Box>
            ) : <Box height="30px" />}
            <CardChoices saveCardChoice={saveCardChoice} />
          </Box>
          <Typography className={classes.footer}>
            <a
              className={classes.link}
              href="https://github.com/dontpressthat"
              target="_blank"
              rel="noreferrer"
            >
              GitHub
            </a>
          </Typography>
        </Box>
      ) : (
        <Welcome createNewRoomOrAddUser={createNewRoomOrAddUser} />
      )}
    </>
  );
};

export default App;

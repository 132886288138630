import React, { useContext } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AppContext } from '../../context/AppContextProvider';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    width: '930px',
    height: '104px',
    borderRadius: '8px'
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '88px',
    width: '65px',
    border: '2px solid lightgray',
    borderRadius: '8px',
    backgroundColor: 'rgba(255, 255, 255, 0.5)'
  },
  number: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '32px',
    fontFamily: 'Poppins',
    height: '100%',
    width: '100%',
    color: '#364747',
    userSelect: 'none',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f0f0f0 !important'
    }
  }
}));

const options = [0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, '?'];

const CardChoices = ({ saveCardChoice }) => {
  const classes = useStyles();
  const { cardChoice, allCardsShown } = useContext(AppContext);

  const onCardClick = (e) => {
    saveCardChoice(e.target.textContent);
  };

  return (
    <Box
      className={classes.container}
      style={{
        border: allCardsShown ? '2px solid white' : '2px solid #d1750d',
      }}
    >
      {options.map((o, i) => {
        return (
          <Box
            key={i}
            className={classes.card}
            onClick={allCardsShown? () => {} : onCardClick}
          >
            <Typography
              className={classes.number}
              style={{
                backgroundColor: o.toString() === cardChoice ? '#e5e5e5' : 'transparent'
              }}
            >
              {o}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default CardChoices;

// Firebase App (the core Firebase SDK) is always required and must be listed first
// import * as firebase from 'firebase/app';
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"
import firebase from 'firebase/compat/app';

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

// Add the Firebase products that you want to use
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/analytics';
// import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyD8OfKpsIFo7mvqOsM2igqA0JpLv7lCbGI',
  authDomain: 'planning-poker-32a32.firebaseapp.com',
  databaseURL: 'https://planning-poker-32a32-default-rtdb.firebaseio.com',
  projectId: 'planning-poker-32a32',
  storageBucket: 'planning-poker-32a32.appspot.com',
  messagingSenderId: '404633920465',
  appId: '1:404633920465:web:d6bda3b65933d8c442f6f0',
  measurementId: 'G-CGHMVJ3MC3'
};

firebase.initializeApp(firebaseConfig);
export const analytics = firebase.analytics();
// const analytics = getAnalytics(app);

export const db = firebase.database();

export const createNewGameRoomOrAddUser = async (roomId, userId, name, isNewRoom) => {
  // Creates a new room with the creator as first user,
  // or adds an additional user to an existing room
  const roomRef = db.ref(`rooms/${roomId}`);
  const usersRef = roomRef.child('users');
  const metaRef = roomRef.child('metadata');
  if (isNewRoom) {
    console.log(isNewRoom);
    await metaRef.update({
      creator: name,
      timestamp: String(new Date())
    });
  }
  return await usersRef.push({
    name,
    userId
  });
};

export const deleteUserFromGameRoom = async (roomId, userId) => {
  // Deletes a user from a room
  // and if they were the last user to leave,
  // this will also delete the room itself
  const usersRef = db.ref(`rooms/${roomId}/users`);
  const userRecords = await usersRef.get().then(snapshot => snapshot.val());

  console.log(userRecords);

  for (const record in userRecords) {
    if (userRecords[record].userId === userId) {
      const userRef = usersRef.child(record);
      if (userRef) {
        userRef.remove();
      }
    }
  }
};

export const deleteGameRoom = async (roomId) => {
  // Deletes a room and all users in it
  const roomRef = db.ref(`rooms/${roomId}`);
  if (roomRef) {
    roomRef.remove();
  }
};

export const saveCardChoiceNum = async (roomId, userId, number) => {
  const usersRef = db.ref(`rooms/${roomId}/users`);
  const userRecords = await usersRef.get().then(snapshot => snapshot.val());

  for (const record in userRecords) {
    if (userRecords[record].userId === userId) {
      const userRef = usersRef.child(record);
      if (userRef) {
        await userRef.update({ cardChoice: number });
        return number;
      }
    }
  }
};

export const showAllCardsOnTable = async (roomId) => {
  const roomRef = db.ref(`rooms/${roomId}`);
  return await roomRef.update({ showAllCards: true });
};

export const resetCardsOnTable = async (roomId) => {
  const roomRef = db.ref(`rooms/${roomId}`);
  roomRef.update({ showAllCards: false });
  const usersRef = roomRef.child('users');
  const userRecords = await usersRef.get().then(snapshot => snapshot.val());

  for (const user in userRecords) {
    usersRef.child(user).update({
      cardChoice: null
    });
  }

  return userRecords;
};

export const getAllNumbersOnTable = async (roomId) => {
  const roomRef = db.ref(`rooms/${roomId}`);
  const usersRef = roomRef.child('users');
  const userRecords = await usersRef.get().then(snapshot => snapshot.val());
  const numbers = [];

  for (const user in userRecords) {
    if (userRecords[user].cardChoice) {
      numbers.push(userRecords[user].cardChoice);
    }
  }

  roomRef.update({ numbers });  
};

export const resetNumbersOnTable = async (roomId) => {
  const roomRef = db.ref(`rooms/${roomId}`);

  roomRef.update({ numbers: [] });  
};

// export const allCardsShownOnTable = async (roomId) => {
//   const roomRef = db.ref(`rooms/${roomId}`);
//   const showAllCards = await roomRef.get('showAllCards').then(snapshot => snapshot.val());
// };

export default firebase;